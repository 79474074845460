import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AboutHeader from './AboutHeader';
import AboutCareers from './AboutCareers';
import AboutTeam from './AboutTeam';
import AboutPartners from './AboutPartners';
import { getMessages } from '../../locales';

const About = () => {
  const messages = getMessages();
  const data = useStaticQuery(graphql`
    query {
      allTeamJson {
        nodes {
          src {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allPartnersJson {
        nodes {
          name
          src {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="about-page">
      <AboutHeader />
      <AboutTeam team={messages['team.quotes']} images={data.allTeamJson} />
      <AboutCareers />
      <AboutPartners partners={data.allPartnersJson} />
    </div>
  );
};

export default About;
