import React from 'react';
import LocationIcon from '../Icons/LocationIcon';
import { getMessages } from '../../locales';

import hiringImg from '../../assets/about/hiring.svg';
import parse from 'html-react-parser';
import Button from '../common/Button/Button';

const AboutCareers = () => {
  const messages = getMessages();

  return (
    <section className="container about-careers">
      <div className="row no-gutters align-items-center">
        <div className="col-lg-6 col-md-12">
          <img
            className="position-absolute"
            src={hiringImg}
            alt={messages['about.careers.title']}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <h2 className="title-lg">{messages['about.careers.title']}</h2>
          <p className="subtitle">{parse(messages['about.careers.subtitle'])}</p>
          <Button
            buttonStyle="redesigned-primary"
            linkTo="/careers"
            title={messages['about.careers.button']}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutCareers;
