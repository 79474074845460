import React from 'react';
import { object } from 'prop-types';

import { getMessages } from '../../locales';

const AboutPartners = ({ partners }) => {
  const messages = getMessages();

  return (
    <section className="container about-partners">
      <h3 className="title-lg">{messages['about.partners.title']}</h3>
      <div className="row align-items-center m-auto">
        {partners.nodes.map(({ name, src }, id) => {
          const columnSize = id < 3 ? '4' : '6';
          return <div key={id} className={`col-${columnSize} col-lg-2 about-partners-member`}>
            <img width="190" height="190" src={src.childImageSharp.fluid.src} alt={name} />
          </div>
        })}
      </div>
    </section>
  );
};

AboutPartners.propTypes = {
  partners: object.isRequired,
};

export default AboutPartners;
